@import url('https://fonts.googleapis.com/css2?family=Lato');

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
  transform: scale(0);
  /* transform: translateY(-150%); */
}

/* .tg-bg-success {
  background-color: #71982d !important;
}

.tg-btn-success {
  background-color: #71982d !important;
  border-color: #71982d !important;
  color: #ffffff;
  text-decoration: none;
}

.tg-btn-success:hover {
  background-color: #02512C !important;
  border-color: #02512C !important;
  color: #ffffff;
}

.tg-btn-outline-success {
  color: #71982d !important;
  border-color: #71982d !important;
}

.tg-btn-outline-success:hover {
  color: #ffffff;
  background-color: #71982d !important;
  border-color: #71982d !important;
}

.tg-text-success{
  --bs-text-opacity: 1;
  color: #02512C !important;
} */

* {
  font-family: 'Lato', san-serif;
}