@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

$theme-colors: (
  tg-light: #71982d,
  tg-dark: #02512C,
);

// Generate `.bg-*` color utilities
@each $value in $theme-colors {
  .#{nth($value, 1)} {
    color: nth($value, 2);
  }

  .bg-#{nth($value, 1)} {
    background-color: nth($value, 2);
  }

  .border-#{nth($value, 1)} {
    border-color: nth($value, 2);
  }

  .text-#{nth($value, 1)} {
    color: nth($value, 2);
  }
}

// Bootstrap and its default variables
@import "../../node_modules/bootstrap/scss/bootstrap";